
// 登入/登出 
export const POST_AUTH_LOGIN_API = `/api/login/`;
export const GET_AUTH_LOGOUT_API = `/api/logout/`;

// Auth
export const GET_ME_CHECK_API = `/api/me/check/`;
export const GET_ME_API = `/api/me/`;

// AuthUser 使用者模組
export const GET_AUTH_USER_CLUB_PATH_API = `/api/me/club/`;
export const PUT_AUTH_USER_SECURITY_API = `/api/me/security/`;
export const POST_AUTH_USER_AGENT_OWNER_API = `/api/me/agent-owner/`;
export const POST_AUTH_USER_ME_EMPLOYEE_API = `/api/me/employee/`;
export const PUT_AUTH_USER_ME_CHANGE_PASSWORD_TICKET_API = (ticket) => `/api/me/change-password/${ticket}/`;
export const DELETE_AUTH_USER_ME_EMPLOYEE_TICKET_API = (ticket) => `/api/me/employee/${ticket}/`;
export const PUT_AUTH_USER_ME_EMPLOYEE_FORGOT_PASSWORD_API = `/api/me/employee/forgot-password/`;
export const GET_AUTH_USER_ME_MAIL_FORGOT_PASSWORD_API = (userName) => `/api/me/mail/forgot-password/${userName}/`;
export const GET_AUTH_USER_ME_CHECK_FORGOT_PASSWORD_TICKET_API = (ticket) => `/api/me/check/change-password/${ticket}/`;
export const POST_AUTH_USER_ME_UNLOCK_SCREEN = `/api/me/unlock-screen/`;
export const POST_AUTH_USER_ME_NON_CHANGE_PASSWORD_TICKET = (ticket) => `/api/me/change-password/keep/${ticket}/`;

// Inbody
export const GET_INBODY_OVERVIEW_OPTIONS_API = `/api/inbody/overview/options/`;
export const GET_EMPLOYEES_OPTIONS_API = `/api/employees/options/`;
export const GET_INBODY_API = `/api/inbody/`; // 取得量身資料清單
export const GET_INBODY_MEMBER_HEIGHT_API = `/api/inbody/member-height/`; // 取得會員身高
export const GET_INBODY_INTERVIEW_NOT_MEASURE_STATUS_API = `/api/inbody/interview-not-measure-status/`; // 面談狀態
export const PUT_INBODY_INTERVIEW_NOT_MEASURE_STATUS_API = GET_INBODY_INTERVIEW_NOT_MEASURE_STATUS_API;
export const POST_INBODY_INTERVIEW_MEMBERID_API = (memberID) => `/api/inbody/interview/${memberID}/`; // 建立量身面談內容
export const GET_INBODY_MEASURE_RECORDS_API = `/api/inbody/measure-records/`; // 取得完整量身資料清單
export const GET_INBODY_TRIGGER_MEASURE_API = `/api/inbody/trigger-measure/`; // 觸發量身,回傳是否可使用
export const POST_INBODY_MEASUREID_SPORT_ADVISE_SAVE_API = (measureID) => `/api/inbody/measure/${measureID}/sport-advise/save/`;
export const POST_INBODY_MEMBERID_MEASURE_TASK_XFACTOR_MEASUREID_API = (memberID, measureID) => `/api/inbody/${memberID}/measure-task-xfactor/${measureID}/`;
export const POST_INBODY_MEMBERID_MEASURE_TASK_AGREED_MEASUREID_API = (memberID, measureID) => `/api/inbody/${memberID}/measure-task-agreed/${measureID}/`;
export const PUT_INBODY_INTERVIEW_API = `/api/inbody/interview/`;
export const POST_CANCEL_MEASURE_API = `/api/inbody/cancel-measure/`;
export const DELETE_INBODY_MEASUREID_API = (measureID) => `/api/inbody/${measureID}/`;
export const POST_INBODY_IMPORT_EXCEL_API = `/api/inbody/import-inbody/`;
export const POST_INBODY_IMPORT_EXCEL_370_DM0536_API = `/api/inbody/import-inbody/370_DM-0536/`;
export const GET_INBODY_MEMBERID_MEASURE_RANGE_DATE_API = (memberID) => `/api/inbody/${memberID}/measure-range-date/`;

export const GET_PURCHASE_RECEIPT_OPTIONS_API = (skip = false) => `/api/stock/purchase-receipt/options/${skip}`;
export const GET_PURCHASE_RECEIPT_ORDER_API = (purchaseOrderID) => `/api/stock/purchase-receipt/${purchaseOrderID}/`;
export const POST_PURCHASE_RECEIPT_OVERVIEW_API = `/api/stock/purchase-receipt/overview/`;
export const DELETE_PURCHASE_RECEIPT_ORDER_API = (purchaseOrderID) => `/api/stock/purchase-receipt/${purchaseOrderID}/`;
export const GET_INSPECTION_ORDER_API = (inspectionOrderID) => `/api/stock/inspection-order/${inspectionOrderID}/`;
export const POST_INSPECTION_ORDER_API = `/api/stock/inspection-order/`;

export const POST_SECURITY_MASK_API = `/api/security-mask/`;
